import { useEffect, useState } from 'react';

import { NULL_ID } from '../../../constants';
import { PROJ_CONTINGENCY_ADDS } from '../../../features';
import { GetItemContingenciesQuery, MilestoneContingencyInfo } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { Chip, CostInput, Icon, IconButton, Select, Tooltip } from '../../scales';

import { useCAEntries } from './ContingencyDrawUtils';

type Props = {
  availableContingencies: MilestoneContingencyInfo[];
  itemDrawInfo?: GetItemContingenciesQuery['getItemContingencies']['draws'][0];
  contingencyCost: Cost;
  hasMultipleDraws: boolean;
  isFullWidth?: boolean;
  onAdd?: () => void;
  onRemove?: () => void;
  onSelect: (contingencyID: UUID) => void;
  onSetValue: (value: number) => void;
  onSetFullAmount: () => void;
};

export const getNegativeEstimateValue = (contingencyCost: Cost) =>
  -getEstimateValue(contingencyCost);

export const getEstimateValue = (contingencyCost: Cost) =>
  contingencyCost && 'value' in contingencyCost ? Number(contingencyCost.value) : 0;

const getDrawCost = (
  contingencyCost: Cost,
  hasMultipleDraws: boolean,
  itemDrawInfo?: GetItemContingenciesQuery['getItemContingencies']['draws'][0]
) => {
  return (itemDrawInfo?.drawFrom && itemDrawInfo?.draw) || hasMultipleDraws
    ? Number(itemDrawInfo?.draw)
    : getEstimateValue(contingencyCost);
};

export default function ContingencyDraw({
  availableContingencies,
  contingencyCost,
  hasMultipleDraws,
  isFullWidth = false,
  itemDrawInfo,
  onAdd,
  onRemove,
  onSelect,
  onSetValue,
  onSetFullAmount,
}: Props) {
  const hasContingencyAddsFeature = useHasFeature(PROJ_CONTINGENCY_ADDS);
  const adjustmentTerm = hasContingencyAddsFeature ? 'adjustment' : 'draw';
  // keep track of the value as the user edits it, and before they submit the changes
  const [cost, setCost] = useState<number>(
    getDrawCost(contingencyCost, hasMultipleDraws, itemDrawInfo)
  );

  const { draw, isDrawingFullAmount } = itemDrawInfo || {};
  // value may be null if the contingencies havent' yet loaded
  // or when making changes to the cost of the contingency
  useEffect(() => {
    if (draw && !cost) {
      setCost(draw);
    }
  }, [draw, cost]);
  // if the item value has changed, we will update the cost to match
  useEffect(() => {
    if (isDrawingFullAmount) {
      setCost(getDrawCost(contingencyCost, hasMultipleDraws, itemDrawInfo));
    }
  }, [isDrawingFullAmount, contingencyCost, hasMultipleDraws, itemDrawInfo]);

  const [submittedRemove, setSubmittedRemove] = useState(false);
  const entries = useCAEntries(availableContingencies, itemDrawInfo?.drawFrom?.id, true);
  const showFullAmount =
    itemDrawInfo?.isDrawingFullAmount || (!hasMultipleDraws && !itemDrawInfo?.drawFrom);

  const contingencySelectFullWidthPlaceholder = hasContingencyAddsFeature
    ? 'Select a Contingency or Allowance...'
    : 'Select where to draw from...';
  const contingencySelectSidebarPlaceholder = hasContingencyAddsFeature
    ? 'Select...'
    : 'Draw from...';
  return (
    <div className="flex items-center justify-between">
      <div
        className={`flex min-w-0 flex-grow items-center gap-2 ${!itemDrawInfo ? 'text-type-inactive' : ''}`}
      >
        <div className="flex min-w-0 max-w-[600px] flex-shrink flex-grow gap-2">
          <div className="min-w-0 flex-shrink flex-grow">
            <Select
              aria-label={contingencySelectFullWidthPlaceholder}
              data-cy="contingency-select"
              defaultValue={NULL_ID}
              entries={entries}
              onChange={onSelect}
              placeholder={
                isFullWidth
                  ? contingencySelectFullWidthPlaceholder
                  : contingencySelectSidebarPlaceholder
              }
              value={itemDrawInfo?.drawFrom?.id || ''}
            />
          </div>
          <div
            className={`min-w-0 flex-shrink-0 flex-grow-0 ${isFullWidth ? 'basis-[224px]' : 'basis-[146px]'}`}
          >
            <CostInput
              data-cy="continency-number-input"
              isDisabled={!itemDrawInfo?.drawFrom}
              onBlur={() => {
                const costValue = hasContingencyAddsFeature ? cost : -Math.abs(cost);
                onSetValue(costValue);
                // are we are going back into full-amount draw markup?
                if (
                  !hasMultipleDraws &&
                  !itemDrawInfo?.isDrawingFullAmount &&
                  Math.abs(getNegativeEstimateValue(contingencyCost)) === Math.abs(costValue) // Its a match!
                ) {
                  onSetFullAmount();
                }
              }}
              onChange={(inputValue) => {
                if (inputValue) {
                  const numberValue = hasContingencyAddsFeature
                    ? inputValue
                    : -Math.abs(inputValue);
                  setCost(numberValue);
                }
              }}
              startAdornment={
                isFullWidth && (
                  <Tooltip
                    content={
                      showFullAmount
                        ? `Amount will always match Item's Full Estimate, even if that amount changes`
                        : `Amount is the custom amount entered here`
                    }
                    placement="bottom"
                  >
                    <Chip text={showFullAmount ? 'Full Estimate' : 'Custom'} />
                  </Tooltip>
                )
              }
              value={cost}
            />
          </div>
        </div>
        {!hasMultipleDraws && (
          <Tooltip
            content="Reset to Full Cost Impact"
            isDisabled={!itemDrawInfo?.drawFrom || itemDrawInfo?.isDrawingFullAmount}
            placement="bottom"
          >
            <IconButton
              aria-label="Revert to Full Amount"
              data-cy="full-amount-button"
              icon={<Icon name="replay" />}
              isDisabled={!itemDrawInfo?.drawFrom || itemDrawInfo?.isDrawingFullAmount}
              onClick={() => {
                onSetFullAmount();
                setCost(getNegativeEstimateValue(contingencyCost));
              }}
              type="secondary"
            />
          </Tooltip>
        )}
        {onRemove && (
          <Tooltip content={`Remove ${adjustmentTerm}`} placement="bottom">
            <IconButton
              aria-label={`Remove ${adjustmentTerm}`}
              data-cy="draw-remove-button"
              icon={<Icon name="delete" />}
              isDisabled={submittedRemove}
              onClick={() => {
                setSubmittedRemove(true);
                onRemove();
              }}
              type="destructive"
            />
          </Tooltip>
        )}
      </div>
      {onAdd && (
        <Tooltip content={`Add ${adjustmentTerm}`} placement="bottom">
          <IconButton
            aria-label={`Add ${adjustmentTerm}`}
            data-cy="draw-add-button"
            icon={<Icon name="add" />}
            onClick={onAdd}
            type="secondary"
          />
        </Tooltip>
      )}
    </div>
  );
}
