import { useMemo } from 'react';

import { NULL_ID } from '../../../constants';
import {
  GetItemContingenciesQuery,
  MilestoneContingenciesQuery,
  ProjectContingenciesQuery,
} from '../../../generated/graphql';
import { capitalizeString } from '../../../utilities/string';
import { SelectEntry } from '../../scales';

export const useCAEntries = (
  availableContingencies:
    | GetItemContingenciesQuery['getItemContingencies']['availableMilestoneContingencies']
    | ProjectContingenciesQuery['projectContingencies']
    | MilestoneContingenciesQuery['milestoneContingencies'],
  drawFromID?: UUID | null,
  hasUnselectedEntry?: boolean
): SelectEntry[] =>
  useMemo(() => {
    const types = new Set<string>(availableContingencies.map((ac) => ac.type));
    const typeEntries = Array.from(types)
      // sort type strings z-a Contingency, Allowance...
      .sort((a, b) => b.localeCompare(a))
      .map((type) => {
        const matchingContingencies = availableContingencies.filter((ac) => ac.type === type);
        const entries = matchingContingencies.map((mc) => {
          const { id, name, isOwnerCost } = mc;
          const isBeingDrawn = 'isBeingDrawn' in mc && mc.isBeingDrawn;
          return {
            id,
            label: name,
            disabled: !!isBeingDrawn && drawFromID !== id,
            badge: isOwnerCost ? 'Owner' : undefined,
          };
        });
        return {
          id: type,
          label: capitalizeString(type.toLowerCase()),
          isSection: true,
          entries,
        };
      });
    if (!hasUnselectedEntry) {
      return typeEntries;
    }
    return [{ id: NULL_ID, label: 'Unselected' }, ...typeEntries];
  }, [availableContingencies, drawFromID, hasUnselectedEntry]);
